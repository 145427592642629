<template>
  <div
    v-if="visibleBanner"
    class="w-11/12 mx-auto flex flex-col relative">
    <div
      class="flex relative cursor-pointer"
      @touchstart="touchStart"
      @touchmove="touchMove"
    >
      <img
        @click="handleBannerClick(selectedBanner)"
        class="rounded-lg shadow-xl object-cover object-top h-52 w-full my-2"
        :src="selectedBanner.imageUrl">
      <div
        @click="handleBannerClick(selectedBanner)"
        class="flex absolute bottom-2 bg-gray-900 bg-opacity-60 text-white w-full rounded-b-lg p-4">
        {{selectedBanner.title}}
      </div>
      <div
        v-if="selectedBannerIdx !== 0"
        @click="changeBanner('prev')"
        class="flex justify-center items-center w-9 h-9 absolute bg-blue-500 rounded-full -left-4 top-28 bg-opacity-70 cursor-pointer">
        <img
          class="w-full h-full"
          src="~@/assets/icons/arrow-left-white.svg"
          alt="arrow">
      </div>
      <div
        v-if="selectedBannerIdx !== bannersLength - 1"
        @click="changeBanner('next')"
        class="flex justify-center items-center w-9 h-9 absolute bg-blue-500 rounded-full -right-4 top-28 bg-opacity-70 cursor-pointer">
        <img
          class="w-full h-full"
          src="~@/assets/icons/arrow-right-white.svg"
          alt="arrow">
      </div>
    </div>
    <div class="flex justify-center items-center space-x-2">
      <div
        v-for="n in bannersLength"
        :class="['w-4 h-4 rounded-full',
         { 'bg-blue-300': n - 1 === selectedBannerIdx },
         { 'bg-blue-500': n - 1 !== selectedBannerIdx}]"
        :key="n"
      >
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { goUrl } from '@/utils/browser'
export default {
  name: 'Banner',
  data () {
    return {
      selectedBannerIdx: 0,
      touchX: null
    }
  },
  created () {
    this.initComponent()
  },
  computed: {
    ...mapState('banner', ['banners']),
    selectedBanner () {
      return this.banners[this.selectedBannerIdx] || {}
    },
    bannersLength () {
      return this.banners.length
    },
    visibleBanner () {
      return this.bannersLength > 0
    }
  },
  methods: {
    ...mapActions('banner', ['fetch']),
    initComponent () {
      this.fetch({
        success: this.runningSlider
      })
    },
    runningSlider () {
      setInterval(() => {
        if (this.selectedBannerIdx === this.bannersLength - 1) this.selectedBannerIdx = 0
        else this.selectedBannerIdx = this.selectedBannerIdx + 1
      }, 3000)
    },
    handleBannerClick ({ type, youtubeVideoId, redirectUrl }) {
      if (type === 'YOUTUBE') return this.$router.push(`/player?v=${youtubeVideoId}`)
      else if (redirectUrl.indexOf('www') > -1) return goUrl(redirectUrl)
      return this.$router.push(redirectUrl)
    },
    changeBanner (direction) {
      // eslint-disable-next-line no-useless-return
      if (direction === 'prev' && this.selectedBannerIdx === 0) return
      // eslint-disable-next-line no-useless-return
      else if (direction === 'next' && this.selectedBannerIdx === this.bannersLength - 1) return
      else if (direction === 'prev') this.selectedBannerIdx -= 1
      else this.selectedBannerIdx += 1
    },
    touchStart (event) {
      this.touchX = event.touches[0].clientX
    },
    touchMove (event) {
      if (!this.touchX) return
      const currentX = event.touches[0].clientX
      const diffX = currentX - this.touchX
      if (diffX > 80) {
        this.changeBanner('prev')
        this.touchX = null
      }
      if (diffX < -80) {
        this.changeBanner('next')
        this.touchX = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.carousel) {
  .carousel__pagination {
    @apply my-2;
    &-item {
      .carousel__pagination-button {
        @apply bg-blue-700 w-4 h-4 rounded-xl;
      }
      .carousel__pagination-button--active {
        @apply bg-blue-300;
      }
    }
  }
}
</style>
